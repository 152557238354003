import React from 'react'
import {useState} from 'react';
import {Link} from "react-router-dom"
import '../styles/Projects.css'
import Implement2 from '../assets/IMG_7355.jpg'
import Implement3 from '../assets/group w logo.png'
import logo from '../assets/RWELogoNoBG.png'



function Projects() {

    const [pblTrigger, pblTriggered] = useState(false);
    const [pbmTrigger, pbmTriggered] = useState(false);
    const [pbrTrigger, pbrTriggered] = useState(false);

    return (
        <div className = "projects">
        
            <div className = "header">
                <h1>Projects</h1>
            </div>
            <h2>DESIGN. ENRICH. NETWORK.</h2>
            <h3>Learn about the current developments at Real World Engineering</h3>
            <div className = "projectsBody">
                

                <div className="projects-body-left">

                    <div className = "pbl-img-container" onMouseEnter={() => pblTriggered(true)} onMouseLeave={() => pblTriggered(false)}>
                        <Link to="/SDTeam"><img src={Implement2}/></Link>
                    </div>

                    {pblTrigger && <div className='text'>Solar Drone Project</div>}

                </div>

                <div className="projects-body-middle" onMouseEnter={() => pbmTriggered(true)} onMouseLeave={() => pbmTriggered(false)}>

                    <div className = "pbm-img-container">
                        <Link to="/DevTeam"><img src={Implement3}/></Link>
                    </div>

                    {pbmTrigger && <div className='text'>Software Development Project</div>}
                    
                </div>

                <div className="projects-body-right" onMouseEnter={() => pbrTriggered(true)} onMouseLeave={() => pbrTriggered(false)}>

                    <div className = "pbr-img-container">
                        <Link to="/RoboArmTeam"><img src={logo}/></Link>
                    </div>

                    {pbrTrigger && <div className='text'>Robotic Arm Design Project</div>}

                </div>

            </div>

            
    </div>
        
  )
}

export default Projects

/*** 
<div className="projectsImplementContainer">
    <a href={"https://drive.google.com/file/d/1IB7p2iwYDHRO5zKXJgnU3CRKj7WcQsWj/view"} target="_blank" rel="noopener noreferrer" >
        <h3>
            Click here to view the Solar Drone V2 Design Review
        </h3>
    </a>
</div>
*/
