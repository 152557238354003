import React from 'react'
import {Link} from "react-router-dom"
import '../styles/SDTeam.css'
import Implement2 from '../assets/group w logo.png'
import logo from '../assets/RWELogoNoBG.png'


function RoboArmTeam() {
    return (
        <div className = "SDTeam">
            
        
            
       
            <div className = "sdBody">
                <h1>Robotic Arm Design Team Project</h1>

                <div className = "sdBodyLeft">
                    <div className = "raTeamPic">
                        <img src={logo}/>
                    </div>
                </div>

                <div className = "sdBodyRight">
                    <p>The newest addition to Real World Engineering, is our mechatronics 
                        based Robotic Arm Design Team which plans to begin its initiative in 
                        Fall 2024. The projects focus will be to engineer a 6-axis 3D Printing 
                        Robotic Arm with the hopes of expanding its capabilities to that of 
                        typical current standard 3D printers. The team is currently accepting 
                        applicants, for more information check our Linktree on Instagram.
                    </p>
                </div>
                

                <h3>Team Information</h3>
                <p>Meetings: TBD</p>
                <h3>Team Leadership</h3>
                <p>Robotic Arm Chief Engineer: Grant Sherman</p>
                

            </div>

            
    </div>
        
  )
}

export default RoboArmTeam

