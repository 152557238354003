import React from 'react'
import {Link} from "react-router-dom"
import '../styles/SDTeam.css'
import Implement2 from '../assets/group w logo.png'


function DevTeam() {
    return (
        <div className = "SDTeam">
            
        
            
       
            <div className = "sdBody">
                <h1>Software Development Project</h1>

                <div className = "sdBodyLeft">
                    <img src={Implement2}/>
                </div>

                <div className = "sdBodyRight">
                    <p>UF Recsport’s Center for Outdoor Recreation and Education (CORE) organizes 
                        around 50 outdoor trips every semester. They have about 30 trip leaders to 
                        manage these trips. Currently, the process of assigning trip leaders to specific 
                        trips is quite manual and time-consuming. Each trip leader ranks the trips based 
                        on their preference. Then, the Trips Program Assistnat manually reviews these 
                        preferences to assign trip leaders to each trip. This project aims to develop a 
                        software solution to streamline and automate this process, making it more efficient 
                        and less labor-intensive. This goal is to create a system where trip leaders can 
                        easily rank trips, and the software automatically assigns them to trips on their 
                        preferences and other factors.
                    </p>
                </div>
                

                <h3>Team Information</h3>
                <p>Meetings: Thursdays 5:30 to 6:30 PM</p>
                <h3>Team Leadership</h3>
                <p>Software Chief Engineer - Aaron Shumer</p>
                

            </div>

            
    </div>
        
  )
}

export default DevTeam

/*** 
<div className="projectsImplementContainer">
    <a href={"https://drive.google.com/file/d/1IB7p2iwYDHRO5zKXJgnU3CRKj7WcQsWj/view"} target="_blank" rel="noopener noreferrer" >
        <h3>
            Click here to view the Solar Drone V2 Design Review
        </h3>
    </a>
</div>
*/
