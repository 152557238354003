import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer'
import Home from './pages/Home';
import Team from './pages/Team';
import Contact from './pages/Contact';
import ScreenSize from './components/ScreenSize'
import Donate from './pages/Donate';
import Projects from './pages/Projects';
import SDTeam from './pages/SDTeam';
import DevTeam from './pages/DevTeam';
import RoboArmTeam from './pages/RoboArmTeam';
import "./fonts/Lato-Font/Lato-Regular.ttf";
import "./fonts/Lato-Font/Lato-Bold.ttf";

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';


function App() {
    
    return (

        <div className="App">
      
            <Router>

                <Navbar/>

                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/team" exact element={<Team/>} />
                    <Route path="/projects" exact element={<Projects/>} />
                    <Route path="/contact" exact element={<Contact/>}/>
                    <Route path="/donate" exact element={<Donate/>}/>
                    <Route path="/SDTeam" exact element={<SDTeam/>}/>
                    <Route path="/DevTeam" exact element={<DevTeam/>}/>
                    
                    <Route path="/RoboArmTeam" exact element={<RoboArmTeam/>}/>

                </Routes>
                
                <Footer/>

            </Router>

        </div>

    );

}

export default App;
