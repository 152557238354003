import React from 'react'
import InstagramOutlined from '../assets/InstaGramIcon.png'
import LinkedinOutlined from '../assets/LinkedinIcon.png'
import LinkTreeLogo from '../assets/LinktreeIcon.png'
import Logo from '../assets/RWELogoNoBG.png'
import MAELogo from '../assets/MAE_Logo_5.png'
import youtubeLogo from '../assets/youtubeIcon.png'
import {Link} from 'react-router-dom'

import '../styles/footer.css'

function Footer() {
  const instagramLink = 'https://www.instagram.com/realworldengineering/'; 
  const linkedinLink = 'https://www.linkedin.com/company/rwe-real-world-engineering/';
  const linktreeLink = "https://linktr.ee/realworldengineering/";
  const youtubeLink = "https://www.youtube.com/@RealWorldEngineering_";
  
  

  return (

    <div className="footer">
        <div className='information'>
            
            <div className="middle">
                <h1>Additional Links</h1>
                <Link to="../contact"><h3>Contact</h3></Link>
                <Link to="../donate"><h3>Donate</h3></Link>
            </div>

            <div className="left">
                <h1>Follow us</h1>
                <div className="socialMedia"> 
                    <div className='instagram'>
                        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                            <img src={InstagramOutlined}/>
                        </a>
                    </div>
                    <div className='instagram'>
                        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
                            <img src={LinkedinOutlined}/>
                        </a>
                    </div>
                    <div className='instagram'>
                        <a href={youtubeLink} target="_blank" rel="noopener noreferrer">
                            <img src = {youtubeLogo}/>
                        </a>
                    </div>
                    <div className='instagram'>
                        <a href={linktreeLink} target="_blank" rel="noopener noreferrer">
                            <img src={LinkTreeLogo}/>
                        </a>
                    </div>     
                </div>
            </div>

        </div>

        

        
        <div className="right">
            <p><span>&copy;</span>2024 REALWORLDENGINEERING</p>
            <img src={Logo}/>
        </div>
    </div>

  )
}

export default Footer
