import React from 'react'
import {Link} from "react-router-dom"
import Slideshow from '../components/Slideshow'
import Logo from '../assets/DENNOBGLOGO.png'
import '../styles/Home.css'

function Home() {
    return (
        <div className="home">

            <div className="header">

                <img src={Logo}/>
                <div className="slider">
                    <Slideshow/>
                </div>

            </div>

            <div className="body">

                <h1>THIS IS RWE.</h1>
                <h3>Real World Engineering is an agile, student-run design firm supported by the University of Florida's 
                department of mechanical and aerospace engineering and technology, from designing a solar powered drone for 
                disaster survey missions to crafting software solutions addressing campus-wide needs. This blend of projects 
                highlights our dedication to leveraging diverse engineering disciplines to tackle real-world challenges.
                </h3>

                <div>
                    <iframe class="ytVid"
                        id='iFrame'
                        src="https://www.youtube.com/embed/gLo10uVCLFM?si=RLRBC7l_sqnCyP5G"
                        title="Introduction To WiseGPT"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>

                <h1>MISSION</h1>
                <p>Real World Engineering is an organization designed to advance students’ knowledge of the engineering design 
                process. By operating like a design firm, we provide our members with unique opportunities to lead and participate 
                in diverse projects, from inception to completion. Our ultimate goal is to cultivate a community of engineers who 
                are not only prepared for their future careers but also committed to making a positive impact on society through 
                their engineering solutions. We host meetings, workshops, and a variety of professional and social events to engage 
                our members with the professional world and each other.
                </p>

                <div className="bodyMiddle">
                    <h1>DESIGN. ENRICH. NETWORK.</h1>
                </div>

                <div className="teamsContainer">
                    
                    <h1>TEAMS</h1>

                    <h3>Solar Drone Team</h3>
                    <p>The Solar Drone Team, Real World Engineering’s inaugural team, since 2022 has had a mission to address 
                    the need for effective post-disaster response strategies so as to navigate through the wreckage in search
                    of lost pets, loved ones, valuables, and generally survey damage done to an area. Traditional aerial search 
                    and rescue methods, reliant on manned aircraft, prove time-consuming, costly, and resource-intensive, prompting 
                    the team to seek a more streamlined approach. Enter the Solar Drone: an innovative solution utilizing autonomous 
                    drones with solar charging capabilities, promising increased efficiency in covering vast areas and maximizing 
                    flight time, thereby reducing costs and enhancing mission success rates. The team is continuing to strive to 
                    integrate solar capabilities and improve standard drone electronics features and is currently engineering Version 
                    3.0 of the solar drone.
                    </p>

                    <h3>Software Team</h3>
                    <p>The Software Development Team’s current mission involves addressing the need for effective trip management  
                    for their client: University of Florida's Center for Outdoor Recreation and Education (CORE). Spearheaded by our
                    VP of Software, the developers, handpicked for their prowess, are focused on crafting a comprehensive full-stack 
                    application tailored for the University's Center for Outdoor Recreation and Education (CORE). By embracing agile 
                    methodologies, Real World Engineering not only ensures efficient development within the project but also nurtures 
                    valuable experience in contemporary software practices amongst the team. The team plans to culminate this project 
                    by the end of the semester and to thereby tackle more requests thereafter.
                    </p>

                </div>

            </div>
            
        </div>
        
    )

}

export default Home

