import React, {useState} from 'react'
import Logo from '../assets/IMG_6705-1.PNG'
import {Link} from 'react-router-dom'
import '../styles/NavBar.css'
import {BarsOutlined} from '@ant-design/icons'
import { DownOutlined } from '@ant-design/icons'


function Navbar() {
    const[openLinks, setOpenLinks] = useState(false)
    const[projectsOpenLinks, setProjectsOpenLinks] = useState(false)
    const[leftProjectsOpenLinks, setLeftProjectsOpenLinks] = useState(false)

    const toggleNavbar = () => {
        setOpenLinks(!openLinks)
    }
    const toggleProjectsDropDown = () => {
        setProjectsOpenLinks(!projectsOpenLinks)
    }
    const toggleLeftProjectsDropDown = () => {
        setLeftProjectsOpenLinks(!leftProjectsOpenLinks)
    }

    const handleLinkClick = () => {
        setProjectsOpenLinks(false);
    };
    const handleLinkClick2 = () => {
        setLeftProjectsOpenLinks(false);
        setOpenLinks(false);
    };
    const otherHandleLinkClick = () => {
        setOpenLinks(false);
    };


    return (

        <div className = "navbar">
        
            <div className="leftSide" id={openLinks ? "open" : "close"}>
                
                <Link to="/" onClick={otherHandleLinkClick}><img src={Logo}/></Link>
                
                <div className="hiddenLinks">
                    <Link to="/team" onClick={otherHandleLinkClick}>Team</Link>
                    <div className="projectsTabWrapper" id={leftProjectsOpenLinks ? "open" : "close"}>
                        <div className="tabWrapper">
                            <Link to="/projects" onClick={otherHandleLinkClick}>Projects</Link>
                            <button className='dropDownButton' onClick={toggleLeftProjectsDropDown}>
                                <DownOutlined/>
                            </button>
                        </div>
                    

                        <div className="projectsHiddenLinks">
                            <Link to="/SDTeam" onClick={handleLinkClick2}>Solar Drone</Link>
                            <Link to="/DevTeam" onClick={handleLinkClick2}>Software Development</Link>
                            <Link to="/roboArmTeam" onClick={handleLinkClick2}>Robotic Arm</Link>
                        </div>

                </div>
                    <Link to="/contact" onClick={otherHandleLinkClick}>Contact</Link>
                    <Link to="/donate" onClick={otherHandleLinkClick}>Donate</Link>
                </div>

            </div>

            <div className="rightSide">

                <div className="tabWrapper">
                    <Link to="/team">Team</Link>
                </div>
                
                <div className="projectsTabWrapper" id={projectsOpenLinks ? "open" : "close"}>
                    <div className="tabWrapper">
                        <Link to="/projects">Projects</Link>
                        <button className='dropDownButton' onClick={toggleProjectsDropDown}>
                            <DownOutlined/>
                        </button>
                    </div>
                    

                    <div className="projectsHiddenLinks">
                        <Link to="/SDTeam" onClick={handleLinkClick}>Solar Drone</Link>
                        <Link to="/DevTeam" onClick={handleLinkClick}>Software Development</Link>
                        <Link to="/roboArmTeam" onClick={handleLinkClick}>Robotic Arm</Link>
                    </div>

                    

                </div>

                <div className="tabWrapper">
                    <Link to="/contact">Contact</Link>
                </div>

                <div className="tabWrapper">
                    <Link to="/donate">Donate</Link>
                </div>
                
            
                
                
            
                <button className="menuButton" onClick ={toggleNavbar}>
                    <BarsOutlined />
                </button>

            </div>
      
        </div>

    )
}

export default Navbar
