import React from 'react'
import {Link} from "react-router-dom"
import '../styles/SDTeam.css'
import Implement2 from '../assets/IMG_2692.jpg'


function SDTeam() {
    return (
        <div className = "SDTeam">
            
        
            
       
            <div className = "sdBody">
                <h1>Solar Drone Project</h1>

                <div className = "sdBodyLeft">
                    <img src={Implement2}/>
                </div>

                <div className = "sdBodyRight">
                    <p>The Solar Drone Team, Real World Engineering’s inaugural team, since 2022 
                        has had a mission to address the need for effective post-disaster response 
                        strategies so as to navigate through the wreckage in search of lost pets, 
                        loved ones, valuables, and generally survey damage done to an area. Traditional 
                        aerial search and rescue methods, reliant on manned aircraft, prove time-consuming, 
                        costly, and resource-intensive, prompting the team to seek a more streamlined approach. 
                        Enter the Solar Drone: an innovative solution utilizing autonomous drones with solar 
                        charging capabilities, promising increased efficiency in covering vast areas and 
                        maximizing flight time, thereby reducing costs and enhancing mission success rates. 
                        The team is continuing to strive to integrate solar capabilities and improve standard drone 
                        electronics features and is currently engineering Version 3.0 of the solar drone.
                    </p>
                </div>

                <h3>V2 Design Review</h3>
                <p>Read up on the technical development of the 2.0 version of our Solar Drone!</p>
                
                <h3>Team Information</h3>
                <p>Meetings: Thursdays 5:00 to 6:00 PM</p>

                <h3>Team Leadership</h3>
                <p>Solar Drone Chief Engineer:   Grant Sherman</p>
                <p>Structures Lead: Braxton Eisel</p>
                <p>Aerodynamics Lead: Austin Thomas</p>
                <p>Manufacturing Lead: Augustin Zurlo</p>
                <p>Electronics Lead: Gage Pollard</p>
                <p>CAD Lead: Anthony(AJ) Sawmiller</p>

            </div>

            
    </div>
        
  )
}

export default SDTeam


