import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../styles/Slideshow.css'

import Slider1 from '../assets/IMG_8904.png'
import donateSlide from '../assets/Donate.jpg'
import teamSlide from '../assets/Leaders.JPG'
import contactSlide from '../assets/Expo.JPG'
import projectsSlide from '../assets/Projects.jpg'



function Slideshow() {
  return (
    <Carousel showIndicators={false} centerSlidePercentage={100} autoPlaySpeed={5000} showThumbs={false} showStatus={false} autoPlay infiniteLoop centerMode className='main-slide'>
                
                <div>
                    <a href="../team">
                        <img src={teamSlide}/>
                    </a>
                </div>

                <div>
                    <a href="../projects">
                        <img src={projectsSlide}/>
                    </a>
                </div>

                <div>
                    <a href="../contact">
                        <img src={contactSlide}/>
                    </a>
                </div>

                <div>
                    <a href="../donate">
                        <img src={donateSlide}/>
                    </a>
                </div>
                
            </Carousel>
  )
}

export default Slideshow